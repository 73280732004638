import HeaderMainSearch from "@components/search/headerMainSearch";
// import SearchBarContainer from "@components/SearchBar/SearchBarContainer";
import Login from "./login";
import request from "@libs/request/Api";
import { Avatar, Drawer, Dropdown, Image, Modal, Space } from "antd";
import Cookies from "js-cookie";
import { inject, observer } from "mobx-react";
import moment from "moment";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { TfiHeart } from "react-icons/tfi";
import useWindowSize from "src/hooks/useWindowSize";

const Header = inject("CoreStore")(
  observer((props) => {
    const { CoreStore, className, router, page } = props;

    const { t, i18n } = useTranslation("common");

    const [lang, setLang] = useState(undefined);
    const [status, setStatus] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);

    const { width } = useWindowSize();
    const isSmallLaptop = width <= 1300 && width >= 999;

    const handleclose = (open) => {
      props.CoreStore.loginModalAction(open);
    };

    const _openSearch = () => {
      props.CoreStore.setOpenSearch(!props.CoreStore.openSearch);
      setStatus(false);
    };
    useEffect(() => {
      return () => {};
    }, [CoreStore.user, CoreStore.isAuth]);
    const country = [
      {
        title: t("lang_german"),
        icon: "/icon/search/germany.svg",
        lang: "de",
      },
      {
        title: t("lang_english"),
        icon: "/icon/search/england.svg",
        lang: "en",
      },
    ];
    useEffect(() => {
      setLang(() => localStorage.getItem("lang") || "de");
    });

    const selectLangIcon = () => {
      let title;

      switch (props.CoreStore.lang) {
        case "de":
          title = "Deutsch";
          break;
        case "en":
          title = "English";
          break;
        default:
          break;
      }
      return title;
    };
    const handleSelectLang = (e) => {
      if (props.CoreStore.isAuth == 0) {
        i18n.changeLanguage(e.lang);
        moment.locale(e.lang);
        try {
          props.CoreStore.setLang(e.lang);
        } catch (e) {}
      } else {
        request
          .post("/user/profile/langauge", { lang: e.lang })
          .then((result) => {
            i18n.changeLanguage(e.lang);
            moment.locale(e.lang);
            try {
              props.CoreStore.setLang(e.lang);
            } catch (e) {}

            router.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };

    const handleSelectLangMobile = (e) => {
      i18n.changeLanguage(e);
      moment.locale(e);
      try {
        props.CoreStore.setLang(e);
      } catch (e) {}
    };

    const loggedMenuItems = [
      {
        key: "1",
        label: (
          <Link href="/communication">
            <a>{t("headMenu.messages")}</a>
          </Link>
        ),
        icon: <img src="/static/icons/message.svg" alt="Messages" />,
      },
      {
        key: "2",
        label: (
          <Link href="/user/booking">
            <a>{t("headMenu.journeys")}</a>
          </Link>
        ),
        icon: <img src="/static/icons/journey.svg" alt="Journeys" />,
      },
      {
        key: "3",
        label: (
          <Link href="/user/profile">
            <a>{t("headMenu.account")}</a>
          </Link>
        ),
        icon: <img src="/static/icons/profile.svg" alt="Profile" />,
      },
      {
        key: "4",
        label: t("headMenu.logout"),
        icon: <img src="/static/icons/logout.svg" alt="Logout" />,
        onClick: () => setLogoutModal(true),
      },
    ];

    const languageMenuItems = country.map((data, i) => ({
      key: `lang-${i}`,
      label: (
        <div className="w-56" onClick={() => handleSelectLang(data)}>
          {data.title}
        </div>
      ),
    }));

    const _renderUserMenu = () => {
      return (
        <div className={"flex flex-row"}>
          <Dropdown
            menu={{
              items: loggedMenuItems,
            }}
            placement="bottomRight"
            className={
              "cursor-pointer hover:border-b hover:border-orange  px-4 py-2"
            }
          >
            <div className={"flex items-center space-x-3"}>
              <Avatar src={CoreStore.user.photo} alt={CoreStore.user.name} />
              <div>
                {CoreStore.user.name == null ? "User" : CoreStore.user.name}
              </div>
              <img
                className="w-9 h-9 p-1"
                src="/static/icons/ardownblack.svg"
                alt=""
              />
            </div>
          </Dropdown>
        </div>
      );
    };

    return (
      <header className={className}>
        <nav
          className={`bg-white py-2.5 shadow-sm ${
            page === "offer" && isSmallLaptop ? "!px-0" : "px-6"
          }`}
        >
          <div
            className={`container flex flex-wrap justify-between items-center ${
              (page === "offer" || page === "search") && isSmallLaptop
                ? "!px-5"
                : "px-0"
            }`}
          >
            <div
              className={`img-logo ${
                page === "berlin"
                  ? "flex items-center gap-x-5 sm:gap-x-0 lg:gap-x-5"
                  : ""
              }`}
            >
              <Link href={"/"}>
                <a href={"/"}>
                  <Image
                    src="/static/images/logo.svg"
                    alt="moverii.de"
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                  />
                </a>
              </Link>

              {page === "berlin" && (
                <div className="items-center gap-x-1 hidden lg:flex">
                  <img
                    src="https://api.moverii.de/cdn/images/landings/berliner-leserreisen/logo.webp"
                    alt="Berliner Leserreisen"
                    className="!w-[38px]"
                  />
                  <p className="text-sm font-medium">
                    Berliner <br /> Leserreisen
                  </p>
                </div>
              )}
            </div>

            {page === "berlin" && (
              <div className="items-center gap-x-1 flex sm:hidden">
                <img
                  src="https://api.moverii.de/cdn/images/landings/berliner-leserreisen/logo.webp"
                  alt="Berliner Leserreisen"
                  className="!w-[38px]"
                />
                <p className="text-sm font-medium">
                  Berliner <br /> Leserreisen
                </p>
              </div>
            )}

            <button
              data-collapse-toggle="mobile-menu"
              type="button"
              onClick={() => setStatus(true)}
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <div className="hidden w-full md:flex md:w-auto">
              <div className="flex items-center mr-[28px]">
                <a
                  href="https://provider.moverii.de"
                  target="_blank"
                  className="text-[#2b2d42] text-sm font-medium"
                >
                  {t("main.for_partner")}
                </a>
              </div>

              <ul className="flex p-0 m-0 flex-row space-x-7 md:mt-0 md:text-sm md:font-medium">
                <li className="flex   self-center cursor-pointer">
                  <Dropdown
                    className={"cursor-pointer "}
                    menu={{
                      items: languageMenuItems,
                    }}
                  >
                    <Space>
                      <span className="ml-1">{selectLangIcon()}</span>
                      <Image
                        src="/main/arrow-down.svg"
                        alt="arrow down icon"
                        width={10}
                        height={5}
                        className="mb-2"
                      />
                    </Space>
                  </Dropdown>
                </li>

                <li className="flex self-center space-x-1 cursor-pointer">
                  <Link href={"/user/wishlist"}>
                    <div className="flex gap-x-1 items-center">
                      <TfiHeart className="text-base font-normal" />
                      {t("headMenu.favorites")}
                    </div>
                  </Link>
                </li>

                <li
                  className="flex self-center space-x-1 cursor-pointer"
                  onClick={_openSearch}
                >
                  <div className={"flex"}>
                    <Image
                      src="/main/search.svg"
                      alt="search icon"
                      width={14}
                      height={"100%"}
                      preview={false}
                    />
                  </div>
                  <p className={"flex"}>{t("main.Suche")}</p>
                </li>

                {CoreStore.isAuth != 1 && (
                  <li
                    className="flex self-center space-x-1 cursor-pointer"
                    onClick={() => {
                      props.CoreStore.changeViewMod("login");
                      handleclose(true);
                    }}
                  >
                    <div className={"flex"}>
                      <Image
                        src="/main/profile.svg"
                        alt="profile icon"
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <div className={"flex"}>{t("main.login_action")}</div>
                  </li>
                )}

                {CoreStore.isAuth != 1 ? (
                  <button
                    className="main-btn"
                    onClick={() => {
                      props.CoreStore.changeViewMod("register");
                      handleclose(true);
                    }}
                  >
                    {t("main.Registrieren")}
                  </button>
                ) : (
                  <>{_renderUserMenu()}</>
                )}
              </ul>
            </div>
          </div>
        </nav>

        <Drawer
          size="xs"
          open={status}
          onClose={() => setStatus(false)}
          placement={"right"}
          className="sideNavMenu"
          closable={false}
          title={
            <div className="flex items-center justify-between">
              <img
                src="/static/images/logo.svg"
                alt="moverii"
                className="-ml-2"
              />
              <IoCloseOutline
                className="text-2xl"
                onClick={() => setStatus(false)}
              />
            </div>
          }
        >
          {CoreStore.isAuth != 1 ? (
            <div className="flex flex-col gap-5 w-full h-full text-black font-heading">
              <span
                className="flex items-center gap-2"
                onClick={() => {
                  props.CoreStore.changeViewMod("register");
                  handleclose(true);
                  setStatus(false);
                }}
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/account.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_register")}
                </p>
              </span>

              <span
                className="flex items-center gap-2 border-b pb-6"
                onClick={() => {
                  props.CoreStore.changeViewMod("login");
                  handleclose(true);
                  setStatus(false);
                }}
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/login.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_login")}
                </p>
              </span>

              <span
                className="flex items-center gap-2 pt-3"
                onClick={() => {
                  props.CoreStore.changeViewMod("login");
                  handleclose(true);
                  setStatus(false);
                }}
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/wishlist.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_whishlist")}
                </p>
              </span>

              <span
                className="flex items-center gap-2 border-b pb-6"
                onClick={_openSearch}
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}main/search.svg`}
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_search")}
                </p>
              </span>

              <span
                className="flex flex-col items-start gap-5 pt-3"
                onClick={() => setStatus(false)}
              >
                <span
                  className="flex gap-3 items-center"
                  onClick={() => handleSelectLangMobile("en")}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <BsCheck2
                      className={`text-2xl ${
                        i18n.language === "de" && `hidden`
                      }`}
                    />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_english")}
                  </p>
                </span>

                <span
                  className="flex gap-2 items-center"
                  onClick={() => handleSelectLangMobile("de")}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <BsCheck2
                      className={`text-2xl ${
                        i18n.language === "en" && `hidden`
                      }`}
                    />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_german")}
                  </p>
                </span>
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-5 w-full h-full text-black font-heading">
              <a
                href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/communication`}
                className="flex items-center gap-2"
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src="https://moverii.de/static/icons/message.svg"
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_logged_messages")}
                </p>
              </a>

              <a
                href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/user/booking`}
                className="flex items-center gap-2 border-b pb-6"
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/journeys.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_logged_journeys")}
                </p>
              </a>

              <a
                href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/wishlist`}
                className="flex items-center gap-2 pt-3"
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/wishlist.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_whishlist")}
                </p>
              </a>

              <a
                href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/user/profile`}
                className="flex items-center gap-2 border-b pb-6"
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/account.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_logged_account")}
                </p>
              </a>

              <span className="flex items-center gap-2" onClick={_openSearch}>
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/search.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_search")}
                </p>
              </span>

              <span
                className="flex items-center gap-2 border-b pb-6"
                onClick={() => {
                  setLogoutModal(true);
                  setStatus(false);
                }}
              >
                <span className="w-[30px] flex justify-start items-center">
                  <img
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/header/log-out.png"
                    }
                    className="w-[23px]"
                  />
                </span>
                <p className="text-base font-medium">
                  {t("main.mobile_logged_logout")}
                </p>
              </span>

              <span
                className="flex flex-col items-start gap-5 pt-3"
                onClick={() => setStatus(false)}
              >
                <span
                  className="flex gap-3 items-center"
                  onClick={() => handleSelectLangMobile("en")}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <BsCheck2
                      className={`text-2xl ${
                        i18n.language === "de" && `hidden`
                      }`}
                    />
                  </span>
                  <p className="text-base font-medium">English</p>
                </span>

                <span
                  className="flex gap-2 items-center"
                  onClick={() => handleSelectLangMobile("de")}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <BsCheck2
                      className={`text-2xl ${
                        i18n.language === "en" && `hidden`
                      }`}
                    />
                  </span>
                  <p className="text-base font-medium">German</p>
                </span>
              </span>
            </div>
          )}
        </Drawer>

        <Modal
          open={logoutModal}
          onCancel={() => setLogoutModal(false)}
          footer={null}
          centered
          className="!w-[480px]"
        >
          <div className="flex gap-3">
            <img src={"/static/icons/logout.svg"} />
            <p className="text-base font-medium">
              {t("main.logout_modal_text")}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-10 w-full">
            <button
              className="py-2 bg-orange rounded-[1.25rem] text-base text-white font-medium"
              onClick={() => setLogoutModal(false)}
            >
              {t("main.logout_modal_cancel")}
            </button>
            <button
              className="py-2 bg-white rounded-[1.25rem] text-base text-black font-medium border border-[#ff9f1c]"
              onClick={() => {
                CoreStore.logout();
                setLogoutModal(false);
                Cookies.remove("rememberLogin");
                window.localStorage.removeItem("rememberLogin");
              }}
            >
              {t("main.logout_modal_logout")}
            </button>
          </div>
        </Modal>

        {props.CoreStore.loginModal && (
          <Login
            open={props.CoreStore.loginModal}
            viewMod={props.CoreStore.viewMode}
            handleclose={handleclose}
            router={router}
          />
        )}

        {/* OLD SEARCH BAR */}
        {props.CoreStore.openSearch && (
          <HeaderMainSearch open={props.CoreStore.openSearch} />
        )}

        {/* NEW SEARCH BAR */}
        {/* {props.CoreStore.openSearch && (
          <SearchBarContainer
            isOpen={props.CoreStore.openSearch}
            setIsOpen={(status) => props.CoreStore.setOpenSearch(status)}
            type="header"
          />
        )} */}
      </header>
    );
  })
);
export default Header;
